import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8cd9fcc0 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _761c2bf6 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _13c448d6 = () => interopDefault(import('../pages/book-trial/index.vue' /* webpackChunkName: "pages/book-trial/index" */))
const _55c0966b = () => interopDefault(import('../pages/change-password/index.vue' /* webpackChunkName: "pages/change-password/index" */))
const _4f50d83a = () => interopDefault(import('../pages/course-confirmation/index.vue' /* webpackChunkName: "pages/course-confirmation/index" */))
const _201e6e96 = () => interopDefault(import('../pages/course-expired/index.vue' /* webpackChunkName: "pages/course-expired/index" */))
const _59da7f12 = () => interopDefault(import('../pages/course-policy/index.vue' /* webpackChunkName: "pages/course-policy/index" */))
const _580d1a0b = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _49255804 = () => interopDefault(import('../pages/cronofy.vue' /* webpackChunkName: "pages/cronofy" */))
const _3c7ef3d6 = () => interopDefault(import('../pages/education-provider.vue' /* webpackChunkName: "pages/education-provider" */))
const _4ede6696 = () => interopDefault(import('../pages/education-provider/agreements/index.vue' /* webpackChunkName: "pages/education-provider/agreements/index" */))
const _0e54df1e = () => interopDefault(import('../pages/education-provider/booking-list/index.vue' /* webpackChunkName: "pages/education-provider/booking-list/index" */))
const _7de1a872 = () => interopDefault(import('../pages/education-provider/company/index.vue' /* webpackChunkName: "pages/education-provider/company/index" */))
const _56418542 = () => interopDefault(import('../pages/education-provider/courses/index.vue' /* webpackChunkName: "pages/education-provider/courses/index" */))
const _438f095e = () => interopDefault(import('../pages/education-provider/dashboard/index.vue' /* webpackChunkName: "pages/education-provider/dashboard/index" */))
const _3956ace9 = () => interopDefault(import('../pages/education-provider/faqs/index.vue' /* webpackChunkName: "pages/education-provider/faqs/index" */))
const _5c0ff1e6 = () => interopDefault(import('../pages/education-provider/help/index.vue' /* webpackChunkName: "pages/education-provider/help/index" */))
const _175c97e0 = () => interopDefault(import('../pages/education-provider/schedule.vue' /* webpackChunkName: "pages/education-provider/schedule" */))
const _622b9c3d = () => interopDefault(import('../pages/education-provider/schedule/calendar.vue' /* webpackChunkName: "pages/education-provider/schedule/calendar" */))
const _75326d1d = () => interopDefault(import('../pages/education-provider/schedule/list.vue' /* webpackChunkName: "pages/education-provider/schedule/list" */))
const _136f77c8 = () => interopDefault(import('../pages/education-provider/schedule/course/_id/index.vue' /* webpackChunkName: "pages/education-provider/schedule/course/_id/index" */))
const _54d0b1d6 = () => interopDefault(import('../pages/education-provider/schedule/session/_id/index.vue' /* webpackChunkName: "pages/education-provider/schedule/session/_id/index" */))
const _67a4fe28 = () => interopDefault(import('../pages/education-provider/teacher/index.vue' /* webpackChunkName: "pages/education-provider/teacher/index" */))
const _283706fd = () => interopDefault(import('../pages/education-provider/courses/_slug/index.vue' /* webpackChunkName: "pages/education-provider/courses/_slug/index" */))
const _048075d8 = () => interopDefault(import('../pages/education-provider/courses/_slug/_courseInstanceId/index.vue' /* webpackChunkName: "pages/education-provider/courses/_slug/_courseInstanceId/index" */))
const _6c1dd1c3 = () => interopDefault(import('../pages/ep-enquiry-form/index.vue' /* webpackChunkName: "pages/ep-enquiry-form/index" */))
const _16e374c0 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _273909a4 = () => interopDefault(import('../pages/getting-there/index.vue' /* webpackChunkName: "pages/getting-there/index" */))
const _c7ea336c = () => interopDefault(import('../pages/host-an-event-with-us/index.vue' /* webpackChunkName: "pages/host-an-event-with-us/index" */))
const _36a0b440 = () => interopDefault(import('../pages/how-it-works/index.vue' /* webpackChunkName: "pages/how-it-works/index" */))
const _12389934 = () => interopDefault(import('../pages/join-us/index.vue' /* webpackChunkName: "pages/join-us/index" */))
const _847c6646 = () => interopDefault(import('../pages/morning-pass/index.vue' /* webpackChunkName: "pages/morning-pass/index" */))
const _533e4a28 = () => interopDefault(import('../pages/notify/index.vue' /* webpackChunkName: "pages/notify/index" */))
const _5a066bba = () => interopDefault(import('../pages/offers/index.vue' /* webpackChunkName: "pages/offers/index" */))
const _3075c24e = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _c9cdf27e = () => interopDefault(import('../pages/preschool-playschool/index.vue' /* webpackChunkName: "pages/preschool-playschool/index" */))
const _0f5395db = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _5e299d47 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _6576def9 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _02bf81f9 = () => interopDefault(import('../pages/profile/agora-usage/index.vue' /* webpackChunkName: "pages/profile/agora-usage/index" */))
const _5d72fbc9 = () => interopDefault(import('../pages/profile/bookmarked-courses/index.vue' /* webpackChunkName: "pages/profile/bookmarked-courses/index" */))
const _bbb7eece = () => interopDefault(import('../pages/profile/children-list/index.vue' /* webpackChunkName: "pages/profile/children-list/index" */))
const _685359be = () => interopDefault(import('../pages/profile/config-notify/index.vue' /* webpackChunkName: "pages/profile/config-notify/index" */))
const _f3467940 = () => interopDefault(import('../pages/profile/offers/index.vue' /* webpackChunkName: "pages/profile/offers/index" */))
const _e5a20c14 = () => interopDefault(import('../pages/profile/payments/index.vue' /* webpackChunkName: "pages/profile/payments/index" */))
const _30f30730 = () => interopDefault(import('../pages/profile/personal-information/index.vue' /* webpackChunkName: "pages/profile/personal-information/index" */))
const _4966f966 = () => interopDefault(import('../pages/profile/recurring-payments/index.vue' /* webpackChunkName: "pages/profile/recurring-payments/index" */))
const _1db7370d = () => interopDefault(import('../pages/profile/your-course/_id/index.vue' /* webpackChunkName: "pages/profile/your-course/_id/index" */))
const _48576977 = () => interopDefault(import('../pages/schedule.vue' /* webpackChunkName: "pages/schedule" */))
const _91c4b6f4 = () => interopDefault(import('../pages/schedule/calendar.vue' /* webpackChunkName: "pages/schedule/calendar" */))
const _76fe01e6 = () => interopDefault(import('../pages/schedule/list.vue' /* webpackChunkName: "pages/schedule/list" */))
const _41217710 = () => interopDefault(import('../pages/shopping-cart/index.vue' /* webpackChunkName: "pages/shopping-cart/index" */))
const _b377bd70 = () => interopDefault(import('../pages/sign-in/index.vue' /* webpackChunkName: "pages/sign-in/index" */))
const _2c61473e = () => interopDefault(import('../pages/sign-up/index.vue' /* webpackChunkName: "pages/sign-up/index" */))
const _e06e86f4 = () => interopDefault(import('../pages/student-care/index.vue' /* webpackChunkName: "pages/student-care/index" */))
const _591806f8 = () => interopDefault(import('../pages/student-care-curriculum/index.vue' /* webpackChunkName: "pages/student-care-curriculum/index" */))
const _ca8efc4c = () => interopDefault(import('../pages/student-care-faqs/index.vue' /* webpackChunkName: "pages/student-care-faqs/index" */))
const _1283013f = () => interopDefault(import('../pages/student-care-pricing/index.vue' /* webpackChunkName: "pages/student-care-pricing/index" */))
const _3ce2de89 = () => interopDefault(import('../pages/student-care-technology/index.vue' /* webpackChunkName: "pages/student-care-technology/index" */))
const _3312d790 = () => interopDefault(import('../pages/teacher-app-guide/index.vue' /* webpackChunkName: "pages/teacher-app-guide/index" */))
const _42018552 = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _56074bfa = () => interopDefault(import('../pages/terms-of-business/index.vue' /* webpackChunkName: "pages/terms-of-business/index" */))
const _5f212306 = () => interopDefault(import('../pages/the-space/index.vue' /* webpackChunkName: "pages/the-space/index" */))
const _3d248596 = () => interopDefault(import('../pages/whats-happening/index.vue' /* webpackChunkName: "pages/whats-happening/index" */))
const _3ed6b1d0 = () => interopDefault(import('../pages/host-an-event-with-us/faqs/index.vue' /* webpackChunkName: "pages/host-an-event-with-us/faqs/index" */))
const _6acc7866 = () => interopDefault(import('../pages/host-an-event-with-us/gallery/index.vue' /* webpackChunkName: "pages/host-an-event-with-us/gallery/index" */))
const _75ba848c = () => interopDefault(import('../pages/payment/add-child/index.vue' /* webpackChunkName: "pages/payment/add-child/index" */))
const _2566b466 = () => interopDefault(import('../pages/payment/payment-successful/index.vue' /* webpackChunkName: "pages/payment/payment-successful/index" */))
const _54b884df = () => interopDefault(import('../pages/preschool-playschool/curriculum/index.vue' /* webpackChunkName: "pages/preschool-playschool/curriculum/index" */))
const _ff298dfe = () => interopDefault(import('../pages/preschool-playschool/faqs/index.vue' /* webpackChunkName: "pages/preschool-playschool/faqs/index" */))
const _63d1f40c = () => interopDefault(import('../pages/preschool-playschool/playschool/index.vue' /* webpackChunkName: "pages/preschool-playschool/playschool/index" */))
const _4ad2a4b8 = () => interopDefault(import('../pages/preschool-playschool/pricing/index.vue' /* webpackChunkName: "pages/preschool-playschool/pricing/index" */))
const _f63e226c = () => interopDefault(import('../pages/registrations/early-years/index.vue' /* webpackChunkName: "pages/registrations/early-years/index" */))
const _ef3a454a = () => interopDefault(import('../pages/registrations/student-care/index.vue' /* webpackChunkName: "pages/registrations/student-care/index" */))
const _359608f4 = () => interopDefault(import('../pages/sign-up/account-exist/index.vue' /* webpackChunkName: "pages/sign-up/account-exist/index" */))
const _029bf331 = () => interopDefault(import('../pages/subscription/confirm/index.vue' /* webpackChunkName: "pages/subscription/confirm/index" */))
const _4a09b6e2 = () => interopDefault(import('../pages/subscription/saved-card-successful/index.vue' /* webpackChunkName: "pages/subscription/saved-card-successful/index" */))
const _617fab37 = () => interopDefault(import('../pages/registrations/early-years/thank-you/index.vue' /* webpackChunkName: "pages/registrations/early-years/thank-you/index" */))
const _a90d4ef0 = () => interopDefault(import('../pages/registrations/student-care/thank-you/index.vue' /* webpackChunkName: "pages/registrations/student-care/thank-you/index" */))
const _fb68a74e = () => interopDefault(import('../pages/courses/providers/_slug/index.vue' /* webpackChunkName: "pages/courses/providers/_slug/index" */))
const _0fbb2b48 = () => interopDefault(import('../pages/app-help/_brandSlug/index.vue' /* webpackChunkName: "pages/app-help/_brandSlug/index" */))
const _08c82106 = () => interopDefault(import('../pages/courses/_slug/index.vue' /* webpackChunkName: "pages/courses/_slug/index" */))
const _d4aae624 = () => interopDefault(import('../pages/invoice/_invoiceId/index.vue' /* webpackChunkName: "pages/invoice/_invoiceId/index" */))
const _2b73378c = () => interopDefault(import('../pages/referral/_referralCode/index.vue' /* webpackChunkName: "pages/referral/_referralCode/index" */))
const _2794e1ab = () => interopDefault(import('../pages/blog/_id/_slug/index.vue' /* webpackChunkName: "pages/blog/_id/_slug/index" */))
const _74912d74 = () => interopDefault(import('../pages/courses/_slug/_referenceId/index.vue' /* webpackChunkName: "pages/courses/_slug/_referenceId/index" */))
const _548e7131 = () => interopDefault(import('../pages/courses/_slug/_referenceId/_id/index.vue' /* webpackChunkName: "pages/courses/_slug/_referenceId/_id/index" */))
const _6367a0bc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6a824e9e = () => interopDefault(import('../pages/_schoolSlug/index.vue' /* webpackChunkName: "pages/_schoolSlug/index" */))
const _4db637a7 = () => interopDefault(import('../pages/_schoolSlug/_courseSlug/index.vue' /* webpackChunkName: "pages/_schoolSlug/_courseSlug/index" */))
const _e14e3f76 = () => interopDefault(import('../pages/_schoolSlug/_courseSlug/_referenceId/index.vue' /* webpackChunkName: "pages/_schoolSlug/_courseSlug/_referenceId/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _8cd9fcc0,
    name: "about"
  }, {
    path: "/blog",
    component: _761c2bf6,
    name: "blog"
  }, {
    path: "/book-trial",
    component: _13c448d6,
    name: "book-trial"
  }, {
    path: "/change-password",
    component: _55c0966b,
    name: "change-password"
  }, {
    path: "/course-confirmation",
    component: _4f50d83a,
    name: "course-confirmation"
  }, {
    path: "/course-expired",
    component: _201e6e96,
    name: "course-expired"
  }, {
    path: "/course-policy",
    component: _59da7f12,
    name: "course-policy"
  }, {
    path: "/courses",
    component: _580d1a0b,
    name: "courses"
  }, {
    path: "/cronofy",
    component: _49255804,
    name: "cronofy"
  }, {
    path: "/education-provider",
    component: _3c7ef3d6,
    name: "education-provider",
    children: [{
      path: "agreements",
      component: _4ede6696,
      name: "education-provider-agreements"
    }, {
      path: "booking-list",
      component: _0e54df1e,
      name: "education-provider-booking-list"
    }, {
      path: "company",
      component: _7de1a872,
      name: "education-provider-company"
    }, {
      path: "courses",
      component: _56418542,
      name: "education-provider-courses"
    }, {
      path: "dashboard",
      component: _438f095e,
      name: "education-provider-dashboard"
    }, {
      path: "faqs",
      component: _3956ace9,
      name: "education-provider-faqs"
    }, {
      path: "help",
      component: _5c0ff1e6,
      name: "education-provider-help"
    }, {
      path: "schedule",
      component: _175c97e0,
      name: "education-provider-schedule",
      children: [{
        path: "calendar",
        component: _622b9c3d,
        name: "education-provider-schedule-calendar"
      }, {
        path: "list",
        component: _75326d1d,
        name: "education-provider-schedule-list"
      }, {
        path: "course/:id",
        component: _136f77c8,
        name: "education-provider-schedule-course-id"
      }, {
        path: "session/:id",
        component: _54d0b1d6,
        name: "education-provider-schedule-session-id"
      }]
    }, {
      path: "teacher",
      component: _67a4fe28,
      name: "education-provider-teacher"
    }, {
      path: "courses/:slug",
      component: _283706fd,
      name: "education-provider-courses-slug"
    }, {
      path: "courses/:slug/:courseInstanceId",
      component: _048075d8,
      name: "education-provider-courses-slug-courseInstanceId"
    }]
  }, {
    path: "/ep-enquiry-form",
    component: _6c1dd1c3,
    name: "ep-enquiry-form"
  }, {
    path: "/faqs",
    component: _16e374c0,
    name: "faqs"
  }, {
    path: "/getting-there",
    component: _273909a4,
    name: "getting-there"
  }, {
    path: "/host-an-event-with-us",
    component: _c7ea336c,
    name: "host-an-event-with-us"
  }, {
    path: "/how-it-works",
    component: _36a0b440,
    name: "how-it-works"
  }, {
    path: "/join-us",
    component: _12389934,
    name: "join-us"
  }, {
    path: "/morning-pass",
    component: _847c6646,
    name: "morning-pass"
  }, {
    path: "/notify",
    component: _533e4a28,
    name: "notify"
  }, {
    path: "/offers",
    component: _5a066bba,
    name: "offers"
  }, {
    path: "/payment",
    component: _3075c24e,
    name: "payment"
  }, {
    path: "/preschool-playschool",
    component: _c9cdf27e,
    name: "preschool-playschool"
  }, {
    path: "/privacy",
    component: _0f5395db,
    name: "privacy"
  }, {
    path: "/products",
    component: _5e299d47,
    name: "products"
  }, {
    path: "/profile",
    component: _6576def9,
    name: "profile",
    children: [{
      path: "agora-usage",
      component: _02bf81f9,
      name: "profile-agora-usage"
    }, {
      path: "bookmarked-courses",
      component: _5d72fbc9,
      name: "profile-bookmarked-courses"
    }, {
      path: "children-list",
      component: _bbb7eece,
      name: "profile-children-list"
    }, {
      path: "config-notify",
      component: _685359be,
      name: "profile-config-notify"
    }, {
      path: "offers",
      component: _f3467940,
      name: "profile-offers"
    }, {
      path: "payments",
      component: _e5a20c14,
      name: "profile-payments"
    }, {
      path: "personal-information",
      component: _30f30730,
      name: "profile-personal-information"
    }, {
      path: "recurring-payments",
      component: _4966f966,
      name: "profile-recurring-payments"
    }, {
      path: "your-course/:id",
      component: _1db7370d,
      name: "profile-your-course-id"
    }]
  }, {
    path: "/schedule",
    component: _48576977,
    name: "schedule",
    children: [{
      path: "calendar",
      component: _91c4b6f4,
      name: "schedule-calendar"
    }, {
      path: "list",
      component: _76fe01e6,
      name: "schedule-list"
    }]
  }, {
    path: "/shopping-cart",
    component: _41217710,
    name: "shopping-cart"
  }, {
    path: "/sign-in",
    component: _b377bd70,
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _2c61473e,
    name: "sign-up"
  }, {
    path: "/student-care",
    component: _e06e86f4,
    name: "student-care"
  }, {
    path: "/student-care-curriculum",
    component: _591806f8,
    name: "student-care-curriculum"
  }, {
    path: "/student-care-faqs",
    component: _ca8efc4c,
    name: "student-care-faqs"
  }, {
    path: "/student-care-pricing",
    component: _1283013f,
    name: "student-care-pricing"
  }, {
    path: "/student-care-technology",
    component: _3ce2de89,
    name: "student-care-technology"
  }, {
    path: "/teacher-app-guide",
    component: _3312d790,
    name: "teacher-app-guide"
  }, {
    path: "/terms-and-conditions",
    component: _42018552,
    name: "terms-and-conditions"
  }, {
    path: "/terms-of-business",
    component: _56074bfa,
    name: "terms-of-business"
  }, {
    path: "/the-space",
    component: _5f212306,
    name: "the-space"
  }, {
    path: "/whats-happening",
    component: _3d248596,
    name: "whats-happening"
  }, {
    path: "/host-an-event-with-us/faqs",
    component: _3ed6b1d0,
    name: "host-an-event-with-us-faqs"
  }, {
    path: "/host-an-event-with-us/gallery",
    component: _6acc7866,
    name: "host-an-event-with-us-gallery"
  }, {
    path: "/payment/add-child",
    component: _75ba848c,
    name: "payment-add-child"
  }, {
    path: "/payment/payment-successful",
    component: _2566b466,
    name: "payment-payment-successful"
  }, {
    path: "/preschool-playschool/curriculum",
    component: _54b884df,
    name: "preschool-playschool-curriculum"
  }, {
    path: "/preschool-playschool/faqs",
    component: _ff298dfe,
    name: "preschool-playschool-faqs"
  }, {
    path: "/preschool-playschool/playschool",
    component: _63d1f40c,
    name: "preschool-playschool-playschool"
  }, {
    path: "/preschool-playschool/pricing",
    component: _4ad2a4b8,
    name: "preschool-playschool-pricing"
  }, {
    path: "/registrations/early-years",
    component: _f63e226c,
    name: "registrations-early-years"
  }, {
    path: "/registrations/student-care",
    component: _ef3a454a,
    name: "registrations-student-care"
  }, {
    path: "/sign-up/account-exist",
    component: _359608f4,
    name: "sign-up-account-exist"
  }, {
    path: "/subscription/confirm",
    component: _029bf331,
    name: "subscription-confirm"
  }, {
    path: "/subscription/saved-card-successful",
    component: _4a09b6e2,
    name: "subscription-saved-card-successful"
  }, {
    path: "/registrations/early-years/thank-you",
    component: _617fab37,
    name: "registrations-early-years-thank-you"
  }, {
    path: "/registrations/student-care/thank-you",
    component: _a90d4ef0,
    name: "registrations-student-care-thank-you"
  }, {
    path: "/courses/providers/:slug",
    component: _fb68a74e,
    name: "courses-providers-slug"
  }, {
    path: "/app-help/:brandSlug",
    component: _0fbb2b48,
    name: "app-help-brandSlug"
  }, {
    path: "/courses/:slug",
    component: _08c82106,
    name: "courses-slug"
  }, {
    path: "/invoice/:invoiceId",
    component: _d4aae624,
    name: "invoice-invoiceId"
  }, {
    path: "/referral/:referralCode",
    component: _2b73378c,
    name: "referral-referralCode"
  }, {
    path: "/blog/:id/:slug",
    component: _2794e1ab,
    name: "blog-id-slug"
  }, {
    path: "/courses/:slug/:referenceId",
    component: _74912d74,
    name: "courses-slug-referenceId"
  }, {
    path: "/courses/:slug/:referenceId/:id",
    component: _548e7131,
    name: "courses-slug-referenceId-id"
  }, {
    path: "/",
    component: _6367a0bc,
    name: "index"
  }, {
    path: "/:schoolSlug",
    component: _6a824e9e,
    name: "schoolSlug"
  }, {
    path: "/:schoolSlug/:courseSlug",
    component: _4db637a7,
    name: "schoolSlug-courseSlug"
  }, {
    path: "/:schoolSlug/:courseSlug/:referenceId",
    component: _e14e3f76,
    name: "schoolSlug-courseSlug-referenceId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
